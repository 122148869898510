import { StorageKey } from '../constants'
import type { Location } from '../router'

export const setLogoutLocation = (location: Location | null) => {
  if (location === null) {
    sessionStorage.removeItem(StorageKey.LOGGED_OUT_FROM_LOCATION)
  }
  sessionStorage.setItem(StorageKey.LOGGED_OUT_FROM_LOCATION, JSON.stringify(location))
}

export const getLogoutLocation = (): Location | null => {
  const savedLocation = sessionStorage.getItem(StorageKey.LOGGED_OUT_FROM_LOCATION)
  if (savedLocation !== null) {
    return JSON.parse(savedLocation)
  }
  return null
}
